<template>
  <div class="card">
		<div class="card__inner" v-on:click="handleExternalClick">
			<div class="card__media">
        <div :class="img_aspect">
          <picture>
            <source :srcset="img_srcset" media="(min-width: 510px)">
            <img :src="img_src" :alt="img_alt" width="480" height="480">
          </picture>
        </div>
			</div>
			<div class="card__body">
        <h3 class="card__title">{{ title }}</h3>
        <a :href="url" class="card__external-link" target="_blank" ref="externalLink">
          <span class="sr-only">View {{ title }} on CodePen</span>
          <IconExternal fill="#ffffff"></IconExternal>
        </a>
			</div>
		</div>
	</div>
</template>

<script>
// Icons
import IconExternal from '../icons/IconExternal.vue'

export default {
  name: 'CardPen',
  props: [
    'title',
    'url',
    'img_srcset',
    'img_src',
    'img_alt',
    'img_aspect'
  ],
  components: {
    IconExternal
  },
  methods: {
    handleExternalClick () {
      this.$refs.externalLink.click()
    }
  }
}
</script>
