<template functional>
  <svg :fill="props.fill" class="icon icon--external" :class="props.classes" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512">
    <path d="M412.88 261.46a20.68 20.68 0 0 0-20.68 20.69v156.88a31.65 31.65 0 0 1-31.61 31.6H72.97a31.65 31.65 0 0 1-31.6-31.6V151.4a31.65 31.65 0 0 1 31.6-31.6h156.88a20.68 20.68 0 0 0 0-41.37H72.97C32.74 78.44 0 111.18 0 151.4v287.62C0 479.25 32.74 512 72.97 512H360.6c40.23 0 72.97-32.74 72.97-72.97V282.14a20.68 20.68 0 0 0-20.68-20.69zM491.32 0H334.44a20.68 20.68 0 1 0 0 41.36h136.2v136.2a20.68 20.68 0 1 0 41.36 0V20.68A20.68 20.68 0 0 0 491.32 0z"/>
    <path d="M505.94 6.06a20.68 20.68 0 0 0-29.25 0l-287.6 287.6a20.68 20.68 0 1 0 29.24 29.26L505.94 35.3a20.68 20.68 0 0 0 0-29.25z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExternal',
  props: {
    fill: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>
