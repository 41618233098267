<template>
  <div class="container">
    <vue-headful
      title="Home | Rhys Matthew | Web Developer"
      description="Rhys Matthew is a Web Developer/Designer and User Interface Developer. From Web Design, Front-End Development or a CMS integrated website, Rhys can help with a range of tasks within Web Development to get your project off the ground."
      keywords="Web Developer, UI Developer, UX Design, Web Design, Front-End Development, JavaScript dev, Freelance, Web Engineer, CSS, PHP, Wordpress Developer, CMS integration, Vue, ES6 JS, Modern JavaScript, Rhys Web Developer"
      :head="{
        'meta[property=\'og:url\']': {content: 'https://rhys.digital'},
        'meta[name=\'twitter:site\']': {content: 'https://rhys.digital'},
        'link[rel=\'canonical\']': {href: 'https://rhys.digital'}
      }"
    />
    <Hero></Hero>

    <h1 class="sr-only">Home</h1>

    <section class="section section--lg" id="projects-home">
      <div class="section__header text--center-desktop-down">
        <h2 tabindex="0">Latest Projects</h2>
      </div>
      <div class="cards">
        <div class="mw--600-desktop-down">
          <div class="row">
            <div class="col-lg-6 mb-1 " v-for="projectItem in projectItems" :key="projectItem.data.id">
              <CardProject
                :key="projectItem.data.id"
                :title="projectItem.data.title"
                :description="projectItem.data.description"
                :img_srcset="projectItem.data.img.lg.url"
                :img_src="projectItem.data.img.url"
                :img_alt="projectItem.data.img.alt"
                img_aspect="aspect--3-2 aspect--1-1-mobileLandscape-down" :live_href="projectItem.data.live_link"
                :git_href="projectItem.data.git_link" :accomplishments="projectItem.data.accomplishments">
              </CardProject>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section--lg section--experiments">
      <div class="section__header text--center-desktop-down">
        <h2 tabindex="0">Experiments</h2>
      </div>
      <div class="mw--600-desktop-down">
        <div class="row center-xs">
          <div class="col-lg-4 mb-1" v-for="codepenItem in codepenItems" :key="codepenItem.data.id">
            <CardPen
            :key="codepenItem.data.id"
            :title="codepenItem.data.title"
            :img_srcset="codepenItem.data.img.lg.url"
            :img_src="codepenItem.data.img.url"
            :img_alt="codepenItem.data.img.alt"
            img_aspect="aspect--3-2 aspect--1-1-mobileLandscape-down"
            :url="codepenItem.data.url.url">
            </CardPen>
          </div>
        </div>
      </div>
    </section>

    <section class="section section--lg section--experiments mb-12-desktop-up">
      <div class="section__header text--center-desktop-down">
        <h2 tabindex="0">Writing</h2>
      </div>
      <div class="mw--600-desktop-down">
        <div class="section__block">
          <div v-if="posts.length !== 0" class="posts">
            <div class="container"></div>
            <div class="row row--lg-gutters">
              <div class="col-xs-12 col-lg-6 mb-1" v-for="post in posts" :key="post.id">
                <div class="post post--card mt-2">
                  <h3 class="post__title"><router-link :to="linkResolver(post)">{{ post.data.title }}</router-link></h3>

                  <p class="post__date"><strong>Posted:</strong> {{ post.data.posted_date }}</p>
                  <p class="post__excerpt">{{ post.data.excerpt[0].text }}</p>
                  <router-link :to="linkResolver(post)" exact class="btn"><span class="btn__inner">View post</span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section--xl bg--gradient text--light text--center-tablet-down">
      <div class="mw--1000">
        <div class="section__block pr-2 pl-2">
          <div class="mw--700-desktop-down">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <h2 class="h2">{{ fields.contactPanelTitle }}</h2>
              </div>
              <div class="col-xs-12 col-md-6">
                <prismic-rich-text :field="fields.contactPanelText"/>
                <router-link to="/contact" exact class="btn text--light mb-4"><span class="btn__inner">Get in touch</span></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// Hero
import Hero from '../components/hero/Hero.vue'

// Cards
import CardProject from '../components/cards/CardProject.vue'
import CardPen from '../components/cards/CardPen.vue'

export default {
  name: 'Home',
  data: function () {
    return {
      projectItems: null,
      codepenItems: null,
      posts: [],
      fields: {
        contactPanelTitle: '',
        contactPanelText: []
      },
      linkResolver: this.$prismic.linkResolver
    }
  },
  components: {
    Hero,
    CardProject,
    CardPen
  },
  created () {
    // Get page content
    this.getHomeContent()
  },
  mounted () {
    // eslint-disable-next-line
    setTimeout (() => {
      // Dispatch pageLoad event
      //
      const pageLoad = new CustomEvent('pageLoad')
      document.dispatchEvent(pageLoad)
    }, 250)
  },
  methods: {
    getHomeContent: function () {
      // Portfolio items
      //
      this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'portfolio_item'), { orderings: '[document.first_publication_date]' }).then((response) => {
        this.projectItems = response.results
      })

      // CodePen items
      //
      this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'codepen_item'), { orderings: '[codepen_item.date]' }).then((response) => {
        this.codepenItems = response.results
      })

      // Contact panel
      //
      this.$prismic.client.getSingle('home')
        .then((response) => {
          this.fields.contactPanelTitle = response.data.contact_panel_title[0].text
          this.fields.contactPanelText = response.data.contact_panel_text
        })

      // Query to get blog posts
      //
      this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'post'), { orderings: '[my.post.date desc]' }
      ).then((response) => {
        this.posts = response.results

        this.posts.forEach((post, index) => {
          this.reformatDate(post.data.posted_date, index)
        })
      })
    },
    reformatDate: function (postDate, postIndex) {
      const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      const dateToFormat = new Date(postDate)

      this.posts[postIndex].data.posted_date = dateToFormat.toLocaleDateString('en-US', dateOptions)
    }
  }
}
</script>
