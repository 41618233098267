<template>
  <div class="card">
		<div class="card__inner" v-on:click="handleOverlayTrigger">
			<div class="card__media">
        <div :class="img_aspect">
          <picture>
            <source :srcset="img_srcset" media="(min-width: 510px)">
            <img :src="img_src" :alt="img_alt" width="480" height="480">
          </picture>
        </div>
			</div>
			<div class="card__body">
				<h3 class="card__title">{{ title }}</h3>
				<p class="card__text">{{ description }}</p>
				<button class="card__overlay__trigger" tabindex="0">
					<span class="sr-only">View {{ title }} details</span>
					<IconMore fill="#ffffff"></IconMore>
				</button>
			</div>
		</div>
		<div class="card__overlay" ref="cardOverlay" v-bind:class="{ active: cardOverlayOpen }">
			<div class="card__overlay__inner">
				<h3>Accomplishments</h3>
				<ul class="list">
          <li v-for="(listItem, index) in accomplishmentsArray" v-bind:key="index">
            <p>
              {{ listItem }}
            </p>
          </li>
				</ul>
				<footer class="card__overlay__footer">
					<ul class="card__overlay__links">
						<li v-if="live_href !== null"><a :href="live_href" class="btn card__overlay__link" target="_blank" tabindex="-1"><span class="btn__inner">View live website</span></a></li>
						<li v-if="git_href !== null"><a :href="git_href" class="btn card__overlay__link" target="_blank" tabindex="-1"><span class="btn__inner">View Git</span></a></li>
					</ul>
				</footer>
				<button v-on:click="handleOverlayDismiss" class="card__overlay__dismiss" tabindex="-1">
					<span class="sr-only">Close {{ title }} details</span>
          <IconClose></IconClose>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
// Icons
import IconMore from '../icons/IconMore'
import IconClose from '../icons/IconClose'

export default {
  name: 'CardWork',
  props: [
    'title',
    'description',
    'img_srcset',
    'img_src',
    'img_alt',
    'img_aspect',
    'accomplishments',
    'live_href',
    'git_href'
  ],
  components: {
    IconMore,
    IconClose
  },
  data: function () {
    return {
      cardOverlayOpen: false,
      accomplishmentsArray: []
    }
  },
  created () {
    this.accomplishmentsArray = this.accomplishments.split(',')
  },
  methods: {
    toggleOverlay: function () {
      const cardOverlayLinks = this.$refs.cardOverlay.querySelectorAll('.card__overlay__link')
      const cardOverlayDismiss = this.$refs.cardOverlay.querySelector('.card__overlay__dismiss')

      // If card overlay is closed then open overlay
      // Else if card overlay is open then close overlay
      //
      if (this.cardOverlayOpen === false) {
        this.cardOverlayOpen = true

        // For each overlay link and dismiss button, enable tabbing
        // and focus on first link
        //
        cardOverlayLinks.forEach((cardOverlayLink, i) => {
          cardOverlayLink.tabIndex = 0
        })
        cardOverlayLinks[0].focus()
        cardOverlayDismiss.tabIndex = 0
      } else if (this.cardOverlayOpen === true) {
        this.cardOverlayOpen = false

        // For each overlay link and dismiss button, disable tabbing
        //
        cardOverlayLinks.forEach((cardOverlayLink, i) => {
          cardOverlayLink.tabIndex = -1
        })
        cardOverlayDismiss.tabIndex = -1
      }
    },
    handleOverlayDismiss: function () {
      // Close overlay
      if (this.cardOverlayOpen === true) {
        this.toggleOverlay()
      }
    },
    handleOverlayTrigger: function (event) {
      // Open overlay
      if (this.cardOverlayOpen === false) {
        this.toggleOverlay()
      }

      // Handle click effect
      event.target.classList.add('is--clicked')
      setTimeout(function () {
        event.target.classList.remove('is--clicked')
      }, 250)
    }
  }
}
</script>
