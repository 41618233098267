<template functional>
  <svg :fill="props.fill" class="icon icon--down-arrow" :class="props.classes" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDownArrow',
  props: {
    fill: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>
