<template functional>
  <svg :fill="props.fill" class="icon icon--more" :class="props.classes" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 408 408">
    <path d="M204 102c28.05 0 51-22.95 51-51S232.05 0 204 0s-51 22.95-51 51 22.95 51 51 51zm0 51c-28.05 0-51 22.95-51 51s22.95 51 51 51 51-22.95 51-51-22.95-51-51-51zm0 153c-28.05 0-51 22.95-51 51s22.95 51 51 51 51-22.95 51-51-22.95-51-51-51z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMore',
  props: {
    fill: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>
