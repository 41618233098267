<template>
  <div class="hero" ref="hero">
    <div class="hero__inner">
      <h1 class="display-1">
        Hey! I'm Rhys,<span class="br"></span>
        a Front-end Developer<span class="br"></span>
        &amp; UI/UX Designer
      </h1>
      <a href="#projects-home" class="hero__scroll-down" v-on:click="handleScroll">
        <span class="sr-only">Scroll to latest projects</span>
        <IconDownArrow fill="#1A2B2B"></IconDownArrow>
      </a>
    </div>
  </div>
</template>

<script>
// Icons
import IconDownArrow from '../icons/IconDownArrow'

// Debounce
import debounce from 'lodash/debounce'

export default {
  name: 'Hero',
  components: {
    IconDownArrow
  },
  mounted () {
    this.setHeroHeight()
    this.handleResize()
  },
  methods: {
    handleScroll: function (e) {
      e.preventDefault()

      const href = e.target.getAttribute('href')
      const offsetTop = document.querySelector(href).offsetTop

      // Check for prefers-reduced-motion setting
      //
      const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

      // Scroll down to content, prefersReducedMotion determines scroll behavior
      //
      scroll({
        top: offsetTop,
        behavior: prefersReducedMotion.matches ? 'auto' : 'smooth'
      })
    },
    setHeroHeight: function () {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      if (windowWidth < 510) {
        this.$refs.hero.style.height = windowHeight - 60 + 'px'
      } else {
        this.$refs.hero.style.height = windowHeight - 80 + 'px'
      }
    },
    handleResize: function () {
      const heroRef = this.$refs.hero

      window.addEventListener('resize', debounce(function () {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

        if (windowWidth < 510) {
          heroRef.style.height = windowHeight - 60 + 'px'
        } else {
          heroRef.style.height = windowHeight - 80 + 'px'
        }
      }, 250, { leading: true, trailing: true }))
    }
  }
}
</script>

<style lang="scss">
// ==============================
// Hero
// ========================================

.hero {
  &__inner {
    position: relative;
    width: 100%;
    height: 100%;

    @include mq($from: tablet) {
      height: 100%;
      justify-content: flex-start;
    }
  }
}

// ==============================
// Hero Heading
// ========================================

.hero h1 {
  position: absolute;
  width: 100%;

  @include mq($until: tablet) {
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @include mq($from: tablet) {
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-height: 420px) {
    font-size: 42px;
    margin-top: -40px;
  }
}

// ==============================
// Hero Down Arrow
// ========================================

.hero__scroll-down {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  animation: heroScrollDownAnimation 2s infinite;
  animation-timing-function: cubic-bezier(.74,.28,.57,.82);

  background: transparent;
  border-radius: 50%;
  transition: background 0.3s;

  @media (prefers-reduced-motion) {
    animation: none;
    transition: none;
  }

  @include mq($from: desktop) {
    &:hover {
      background: $color-grey-light;
    }
  }

  .icon--down-arrow {
    width: 42px;
    height: 42px;
    pointer-events: none;

    @include mq($from: tablet) {
      width: 44px;
      height: 44px;
    }

    @include mq($from: desktop) {
      width: 48px;
      height: 48px;
    }
  }
}

@keyframes heroScrollDownAnimation {
  0% {
    bottom: 20px;
  }
  25% {
    bottom: 0px;
  }
  60% {
    bottom: 20px;
  }
}

</style>
